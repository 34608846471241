<template>
  <div>
  <el-dialog
      :visible.sync="dialogVisible"
      width="90%"
      :before-close="closeDialog"
      :close-on-click-modal="false"
      top="2vh"
      :title="showTitle()"
  >
    <el-form
        label-width="140px"
        :model="formAllTabEmployee"
        label-position="left"
        :rules="rules"
        ref="formAllTabEmployee"
    >
      <el-tabs type="card" v-model="activeTabName">
        <el-tab-pane label="Thông tin" name="infoTab">
          <el-row :gutter="40">
            <el-col :span="4">
              <div class="grid-content">
                <el-image
                    v-if="
                    !!formAllTabEmployee.updateEmployeeMainInfoRequest.avatarView &&
                    showPicutreInitial
                  "
                    style="width: 150px; height: 150px; float: left; margin-bottom: 10px"
                    :src="formAllTabEmployee.updateEmployeeMainInfoRequest.avatarView"
                    fit="fill"
                ></el-image>
                <el-upload
                    :disabled="showDetail"
                    action
                    class="avatar-uploader"
                    :show-file-list="true"
                    list-type="picture-card"
                    :on-change="toggleUpload"
                    :on-remove="handleRemove"
                    :auto-upload="false"
                    :class="{ hideUpload: !showUpload }"
                    :limit="1"
                    ref="upload"
                >
                  <span
                      v-if="!!formAllTabEmployee.updateEmployeeMainInfoRequest.avatarView"
                  >
                    <i class="el-icon-plus avatar-uploader-icon">Cập nhật ảnh</i>
                  </span>
                  <span v-else>
                    <i class="el-icon-plus avatar-uploader-icon">Thêm ảnh</i>
                  </span>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible1">
                  <img width="100%" :src="dialogImageUrl" alt/>
                </el-dialog>
                <div style="margin-bottom: 10px">
                  <el-input
                      style="width: 150px; margin-top: 10px"
                      :disabled="showDetail"
                      v-model="formAllTabEmployee.updateEmployeeMainInfoRequest.code"
                  ></el-input>
                </div>
                <div>
                  <el-radio-group
                      :disabled="showDetail"
                      v-model="
                      formAllTabEmployee.updateEmployeeMainInfoRequest.employeeStatus
                    "
                      class="group-radio-status"
                  >
                    <el-radio label="Đang làm" class="kids-status">Đang làm</el-radio>
                    <el-radio label="Tạm nghỉ" class="kids-status">Tạm nghỉ</el-radio>
                    <el-radio label="Nghỉ làm" class="kids-status">Nghỉ làm</el-radio>
                  </el-radio-group>
                </div>

                <div>
                  <el-form-item
                      prop="updateEmployeeMainInfoRequest.dateRetain"
                      style="margin-left: -120px"
                      v-if="
                      formAllTabEmployee.updateEmployeeMainInfoRequest.employeeStatus ==
                      'Tạm nghỉ'
                    "
                  >
                    <el-date-picker
                        style="width: 160px"
                        type="date"
                        format="dd-MM-yyyy"
                        value-format="yyyy-MM-dd"
                        placeholder="Ngày tạm nghỉ"
                        v-model="
                        formAllTabEmployee.updateEmployeeMainInfoRequest.dateRetain
                      "
                    ></el-date-picker>
                  </el-form-item>

                  <el-form-item
                      prop="updateEmployeeMainInfoRequest.dateLeave"
                      style="margin-left: -120px"
                      v-if="
                      formAllTabEmployee.updateEmployeeMainInfoRequest.employeeStatus ==
                      'Nghỉ làm'
                    "
                  >
                    <el-date-picker
                        style="width: 160px"
                        v-model="formAllTabEmployee.updateEmployeeMainInfoRequest.dateLeave"
                        type="date"
                        format="dd-MM-yyyy"
                        value-format="yyyy-MM-dd"
                        placeholder="Ngày nghỉ làm"
                    ></el-date-picker>
                  </el-form-item>
                </div>
                <div style="margin: 20px 0"></div>
              </div>
            </el-col>
            <el-col :span="10">
              <div class="grid-content">
                <el-form-item
                    label="Họ tên"
                    prop="updateEmployeeMainInfoRequest.fullName"
                >
                  <el-input
                      :disabled="showDetail"
                      v-model="formAllTabEmployee.updateEmployeeMainInfoRequest.fullName"
                  ></el-input>
                </el-form-item>
                <el-form-item
                    label="Ngày sinh"
                    prop="updateEmployeeMainInfoRequest.birthday"
                >
                  <el-date-picker
                      :disabled="showDetail"
                      :clearable="false"
                      v-model="formAllTabEmployee.updateEmployeeMainInfoRequest.birthday"
                      format="dd-MM-yyyy"
                      :picker-options="pickerOptions"
                      value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="Chọn ngày sinh"
                      style="width: 100%"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item
                    prop="updateEmployeeMainInfoRequest.gender"
                    label="Giới tính"
                >
                  <el-radio-group
                      :disabled="showDetail"
                      v-model="formAllTabEmployee.updateEmployeeMainInfoRequest.gender"
                  >
                    <el-radio label="Nam">Nam</el-radio>
                    <el-radio label="Nữ">Nữ</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                    label="Số điện thoại"
                    prop="updateEmployeeMainInfoRequest.phone"
                >
                  <el-input
                      :disabled="showDetail"
                      v-model="formAllTabEmployee.updateEmployeeMainInfoRequest.phone"
                  ></el-input>
                </el-form-item>
                <el-form-item
                    label="Ngày vào"
                    prop="updateEmployeeMainInfoRequest.startDate"
                >
                  <el-date-picker
                      :disabled="showDetail"
                      v-model="formAllTabEmployee.updateEmployeeMainInfoRequest.startDate"
                      type="date"
                      format="dd-MM-yyyy"
                      value-format="yyyy-MM-dd"
                      style="width: 100%"
                      placeholder="Chọn ngày vào"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item label="Email" prop="updateEmployeeMainInfoRequest.email">
                  <el-input
                      :disabled="showDetail"
                      v-model="formAllTabEmployee.updateEmployeeMainInfoRequest.email"
                  ></el-input>
                </el-form-item>
                <el-form-item
                    label="Số định danh"
                    prop="updateEmployeeMainInfoRequest.cmnd"
                >
                  <el-input
                      :disabled="showDetail"
                      v-model="formAllTabEmployee.updateEmployeeMainInfoRequest.cmnd"
                  ></el-input>
                </el-form-item>
                <el-form-item
                    label="Ngày cấp"
                    prop="updateEmployeeMainInfoRequest.cmndDate"
                >
                  <el-date-picker
                      :disabled="showDetail"
                      v-model="formAllTabEmployee.updateEmployeeMainInfoRequest.cmndDate"
                      style="width: 100%"
                      format="dd-MM-yyyy"
                      value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="Chọn ngày cấp"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item
                    label="Ngân hàng"
                >
                  <el-input
                      :disabled="showDetail"
                      v-model="formAllTabEmployee.updateEmployeeMainInfoRequest.nameBank"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item
                    label="Số tài khoản"
                >
                  <el-input
                      type="number"
                      :disabled="showDetail"
                      v-model="formAllTabEmployee.updateEmployeeMainInfoRequest.bankAccountNumber"
                  >
                  </el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="10">
              <div class="grid-content">
                <el-form-item
                    label="Địa chỉ thường trú"
                    prop="updateEmployeeMainInfoRequest.permanentAddress"
                >
                  <el-input
                      :disabled="showDetail"
                      v-model="
                      formAllTabEmployee.updateEmployeeMainInfoRequest.permanentAddress
                    "
                  ></el-input>
                </el-form-item>
                <el-form-item
                    label="Chỗ ở hiện tại"
                    prop="updateEmployeeMainInfoRequest.address"
                >
                  <el-input
                      :disabled="showDetail"
                      v-model="formAllTabEmployee.updateEmployeeMainInfoRequest.address"
                  ></el-input>
                </el-form-item>
                <el-form-item label="Dân tộc" prop="updateEmployeeMainInfoRequest.ethnic">
                  <el-input
                      :disabled="showDetail"
                      clearable
                      v-model="formAllTabEmployee.updateEmployeeMainInfoRequest.ethnic"
                  ></el-input>
                </el-form-item>
                <el-form-item
                    label="Hôn nhân"
                    prop="updateEmployeeMainInfoRequest.marriedStatus"
                >
                  <el-select
                      :disabled="showDetail"
                      v-model="
                      formAllTabEmployee.updateEmployeeMainInfoRequest.marriedStatus
                    "
                      filterable
                      clearable
                      placeholder
                  >
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                    label="Số con"
                    prop="updateEmployeeMainInfoRequest.numberChildren"
                >
                  <el-input
                      :disabled="showDetail"
                      v-model="
                      formAllTabEmployee.updateEmployeeMainInfoRequest.numberChildren
                    "
                  ></el-input>
                </el-form-item>
                <el-form-item
                    label="Trình độ"
                    prop="updateEmployeeMainInfoRequest.educationLevel"
                >
                  <el-input
                      :disabled="showDetail"
                      v-model="
                      formAllTabEmployee.updateEmployeeMainInfoRequest.educationLevel
                    "
                  ></el-input>
                </el-form-item>

                <el-form-item
                    label="Đối tượng"
                    prop="updateEmployeeMainInfoRequest.idAccountTypeList"
                >
                  <el-select
                      :disabled="showDetail"
                      v-model="
                      formAllTabEmployee.updateEmployeeMainInfoRequest.idAccountTypeList
                    "
                      multiple
                      placeholder="Chọn đối tượng"
                      clearable
                      filterable
                  >
                    <el-option
                        v-for="item in accountTypeList"
                        :key="item.id"
                        :value="item.id"
                        :label="item.name"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                    label="Ngày ký hợp đồng"
                    prop="updateEmployeeMainInfoRequest.contractDate"
                >
                  <el-date-picker
                      :disabled="showDetail"
                      v-model="
                      formAllTabEmployee.updateEmployeeMainInfoRequest.contractDate
                    "
                      type="date"
                      format="dd-MM-yyyy"
                      value-format="yyyy-MM-dd"
                      style="width: 100%"
                      placeholder="Ngày ký hợp đồng"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item
                    label="Ngày hết hạn"
                    prop="updateEmployeeMainInfoRequest.endDate"
                >
                  <el-date-picker
                      :disabled="showDetail"
                      v-model="formAllTabEmployee.updateEmployeeMainInfoRequest.endDate"
                      type="date"
                      format="dd-MM-yyyy"
                      value-format="yyyy-MM-dd"
                      style="width: 100%"
                      placeholder="Ngày hết hạn"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item label="Ghi chú" prop="updateEmployeeMainInfoRequest.note">
                  <el-input
                      :disabled="showDetail"
                      v-model="formAllTabEmployee.updateEmployeeMainInfoRequest.note"
                      type="textarea"
                      :rows="1"
                      style="width: 100%"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </el-tab-pane>

        <!-- tab phong ban -->
        <el-tab-pane label="Phòng ban">
          <div class="table-content row-data">
            <el-table
                highlight-current-row
                :data="formAllTabEmployee.tabDepartmentInEmployee"
                :header-cell-style="tableHeaderColor"
                :max-height="$tableMaxHeight"
                border
            >
              <el-table-column
                  type="index"
                  label="STT"
                  width="50"
                  align="center"
              ></el-table-column>
              <el-table-column
                  prop="departmentName"
                  label="Tên phòng ban"
                  align="center"
              ></el-table-column>
              <el-table-column label="Chọn phòng ban" align="center">
                <template slot-scope="scope">
                  <el-checkbox :disabled="showDetail" v-model="scope.row.checkDepartment"></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column label="Chức vụ" align="center">
                <template slot-scope="scope">
                  <el-input
                      :disabled="showDetail"
                      v-model="scope.row.position"
                      placeholder="Điền chức vụ"
                  ></el-input>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>

        <!-- tab chuyen mon -->
        <el-tab-pane label="Chuyên môn">
          <div class="table-content row-data">
            <el-table
                highlight-current-row
                :data="formAllTabEmployee.tabProfessionalInEmployee"
                :header-cell-style="tableHeaderColor"
                :max-height="$tableMaxHeight"
                border
            >
              <el-table-column
                  type="index"
                  label="STT"
                  width="50"
                  align="center"
              ></el-table-column>
              <el-table-column
                  prop="className"
                  label="Tên Lớp"
                  align="center"
              ></el-table-column>
              <el-table-column label="Lớp dạy" align="center">
                <template slot-scope="scope">
                  <el-checkbox
                      :disabled="showDetail"
                      @change="selectionMultiProfessional(scope.$index, scope.row)"
                      v-model="scope.row.checkIsClass"
                  ></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column label="Chủ nhiệm" align="center">
                <template slot-scope="scope">
                  <el-checkbox
                      :disabled="showDetail"
                      v-model="scope.row.isMaster"
                      v-if="scope.row.checkIsClass"
                  ></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column label="Chọn môn" align="center">
                <template slot-scope="scope">
                  <el-select
                      :disabled="showDetail"
                      v-if="scope.row.checkIsClass"
                      v-model="scope.row.listIdSubject"
                      placeholder="Chọn môn học"
                      multiple
                  >
                    <el-option
                        v-for="item in scope.row.subjectResponseList"
                        :key="item.id"
                        :label="item.subjectName"
                        :value="item.id"
                    ></el-option>
                  </el-select>
                  <el-select
                      v-else
                      disabled
                      v-model="scope.row.listIdSubject"
                      placeholder="Chọn môn học"
                      multiple
                  >
                    <el-option
                        v-for="item in scope.row.subjectResponseList"
                        :key="item.id"
                        :label="item.subjectName"
                        :value="item.id"
                    ></el-option>
                  </el-select>
                </template>
              </el-table-column>
            </el-table>
            <br/>
          </div>
        </el-tab-pane>
        <el-tab-pane label="App Teacher">
          <!-- table 1 -->
          <el-table
              class="table-icon-1"
              border
              :data="teacherIconAppUpdate.teacherIconUpdate1"
              highlight-current-row
              :header-cell-style="tableHeaderColor"
              :max-height="$tableMaxHeight"
          >
            <el-table-column type="index"></el-table-column>
            <el-table-column prop="iconName" label="Chức năng"></el-table-column>
            <el-table-column label="Hiển thị" align="center">
              <template slot-scope="scope">
                <el-checkbox :disabled="showDetail" v-model="scope.row.statusShow"></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column label="Mở khóa" align="center">
              <template slot-scope="scope">
                <el-checkbox
                    :disabled="showDetail"
                    v-if="scope.row.rootLockStatus"
                    v-model="scope.row.status"
                ></el-checkbox>
                <el-checkbox v-else disabled></el-checkbox>
              </template>
            </el-table-column>
          </el-table>

          <!-- table 2 -->
          <el-table
              class="table-icon-2"
              border
              :data="teacherIconAppUpdate.teacherIconUpdate2"
              highlight-current-row
              :header-cell-style="tableHeaderColor"
              :max-height="$tableMaxHeight"
          >
            <el-table-column type="index"></el-table-column>
            <el-table-column prop="iconName" label="Chức năng"></el-table-column>
            <el-table-column label="Hiển thị" align="center">
              <template slot-scope="scope">
                <el-checkbox :disabled="showDetail" v-model="scope.row.statusShow"></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column label="Mở khóa" align="center">
              <template slot-scope="scope">
                <el-checkbox
                    :disabled="showDetail"
                    v-if="scope.row.rootLockStatus"
                    v-model="scope.row.status"
                ></el-checkbox>
                <el-checkbox v-else disabled></el-checkbox>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="Cấu hình Cam" name="commonConfig">
          <el-row>
            <el-col :span="5" :offset="7">
              <el-checkbox
                  :disabled = "showDetail"
                  v-model="formAllTabEmployee.updateEmployeeMainInfoRequest.oneCamStatus"
              ></el-checkbox>
              <span style="margin-left: 10px">Cho phép xem Camera ở OneCamPlus không?</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col :offset="7">
              <el-table
                  style="width: 700px; margin-top: 10px"
                  ref="multipleTable"
                  element-loading-spinner="el-icon-loading"
                  element-loading-background="rgba(255,255,255, 0)"
                  :data="employeeCamList"
                  highlight-current-row
                  :header-cell-style="tableHeaderColor"
                  border
              >
                <el-table-column
                    type="index"
                    label="STT"
                    width="50"
                    align="center"
                ></el-table-column>
                <el-table-column prop="cameraName" label="Tên cam"></el-table-column>
                <el-table-column prop="active" label="Trạng thái" width="100px" align="center">
                  <template slot-scope="scope">
                    <el-checkbox
                        :disabled="scope.row.existClass || showDetail"
                        v-model="scope.row.active"
                        style="margin-left: 10px"
                    ></el-checkbox>
                  </template>
                </el-table-column>
                <el-table-column
                    label="Tác vụ"
                    width="120"
                    align="center"
                >
                  <template slot-scope="scope">
                    <el-button
                        :disabled="scope.row.existClass || !scope.row.active || showDetail"
                        size="mini"
                        type="success"
                        @click="handleSettingCame(scope.row.idCamera, scope.row.cameraName)"
                    >Thiết lập
                    </el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-col>

          </el-row>

        </el-tab-pane>
      </el-tabs>
    </el-form>
    <div class="tab-infor-button" v-if="showButtonClick">
      <el-button
          type="danger"
          size="medium"
          @click="closeDialogByButton('formAllTabEmployee')"
      >
        <i class="el-icon-circle-close"/>
        <span>Đóng</span>
      </el-button>
      <el-button
          type="success"
          size="medium"
          :loading="loadingButton"
          mini
          @click="submitForm('formAllTabEmployee')"
      >
        <i class="el-icon-circle-check"/>
        <span>Lưu</span>
      </el-button>
    </div>
  </el-dialog>
  <CamSettingEmployeeDialog
      :showSettingCamera="showSettingCamera"
      :idEmployee = "idEmployee"
      :idCamera = "idCamera"
      :nameCamera = "nameCamera"
      @close = "handleCloseSettingCameraDialog"
  />
  </div>
</template>

<script>
import EmployeeDataService from "@/services/EmployeeService/EmployeeDataService";
import employeeDataService from "@/services/EmployeeService/EmployeeDataService";
import {mapActions} from "vuex";
import CamSettingEmployeeDialog from "@/views/employee/CamSettingEmployeeDialog.vue";

export default {
  components: {CamSettingEmployeeDialog},
  props: {
    dialogVisible: null,
  },
  data() {
    return {
      showSettingCamera: false,
      idCamera: 0,
      idEmployee: 0,
      nameCamera: "",
      employeeCamList: [],
      showDetail: false,
      showPicutreInitial: true,
      fileList: [],
      dialogImageUrl: "",
      dialogVisible1: false,
      fileAvatar: "",
      showUpload: true,
      src: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      avatar: {},
      isMasterAccountType: true,
      idEmployeeEdit: "",
      accountTypeList: [],
      showButtonClick: true,
      value: "",
      radiogender: "",
      options: [
        {
          value: "Độc thân",
          label: "Độc thân",
        },
        {
          value: "Đã kết hôn",
          label: "Đã kết hôn",
        },
      ],
      teacherIconAppUpdate: {
        teacherIconUpdate1: [],
        teacherIconUpdate2: [],
      },
      plusIconAppUpdate: {
        plusIconUpdate1: [],
        plusIconUpdate2: [],
      },
      notifyPlusIconAppUpdate: {
        notifyPlusIconUpdate1: [],
        notifyPlusIconUpdate2: [],
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      loadingButton: false,
      formAllTabEmployee: {
        updateEmployeeMainInfoRequest: {
          id: "",
          idAccountTypeList: [],
          fullName: "",
          birthday: "",
          address: "",
          gender: "",
          email: "",
          phone: "",
          cmnd: "",
          cmndDate: "",
          permanentAddress: "",
          marriedStatus: "",
          numberChildren: "",
          educationLevel: "",
          startDate: "",
          contractDate: "",
          endDate: "",
          employeeStatus: "",
          note: "",
          dateLeave: "",
          dateRetain: "",
          avatar: "",
          avatarView: "",
          bankAccountNumber:"",
          nameBank: ""
        },
        tabDepartmentInEmployee: [],
        tabDepartmentRequestList: [],
        tabProfessionalInEmployee: [],
        tabProfessionalRequestList: [],
        notifyAppIconPlusRequestList: [],
        idSchool: "",
      },
      activeTabName: "infoTab",
      rules: {
        updateEmployeeMainInfoRequest: {
          fullName: [
            {
              required: true,
              message: "Họ tên không được để trống",
              trigger: "blur",
            },
          ],
          phone: [
            {
              required: true,
              message: "Số điện thoại không được để trống",
              trigger: "change",
            },
            {
              min: 10,
              max: 10,
              message: "Số điện thoại bao gồm 10 số",
              trigger: "change",
            },
          ],
          gender: [
            {
              required: true,
              message: "Giới tính không được để trống",
              trigger: "blur",
            },
          ],
          startDate: [
            {
              required: true,
              message: "Ngày vào không được để trống",
              trigger: "blur",
            },
          ],
          birthday: [
            {
              required: true,
              message: "Giới tính không được để trống",
              trigger: "blur",
            },
          ],
          dateLeave: [
            {
              required: true,
              message: "Ngày nghỉ làm không được để trống",
              trigger: "change",
            },
          ],
          dateRetain: [
            {
              required: true,
              message: "Ngày tạm nghỉ không được để trống",
              trigger: "change",
            },
          ],
          email: [
            {
              type: "email",
              message: "Email không đúng định dạng",
              trigger: ["blur", "change"],
            },
          ],
        },
      },
    };
  },
  methods: {
    ...mapActions('employeeStore', ['fetchDataEmployeeList']),
    tableHeaderColor() {
      return "background-color: #78a5e7;color: #fff;font-weight: bold;border:1px solid #fff";
    },
    closeDialog() {
      this.activeTabName = "infoTab";
      this.$refs.upload.clearFiles();
      this.formAllTabEmployee.updateEmployeeMainInfoRequest.avatarView = "";
      this.$refs["formAllTabEmployee"].resetFields();
      this.$emit("dialog-close");
      this.showPicutreInitial = true;
      this.$parent.handleCurrentChangeAfter();
    },
    //click when click button
    closeDialogByButton(fromData) {
      this.activeTabName = "infoTab";
      this.$refs.upload.clearFiles();
      this.formAllTabEmployee.updateEmployeeMainInfoRequest.avatarView = "";
      this.$refs[fromData].resetFields();
      this.$emit("dialog-close");
      this.showUpload = true;
      this.showPicutreInitial = true;
    },
    getEmployeeById(idEmployee) {
      this.idEmployeeEdit = idEmployee;
      EmployeeDataService.getEmployeeById(idEmployee)
          .then((response) => {
            console.log(response.data.data)
            this.formAllTabEmployee.updateEmployeeMainInfoRequest =
                response.data.data
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getEmployeeByIdAndIdSchool(idEmployee, idSchool) {
      this.formAllTabEmployee.idSchool = idSchool;
      this.idEmployeeEdit = idEmployee;
      EmployeeDataService.getEmployeeByIdAndIdSchool(idEmployee, idSchool)
          .then((response) => {
            this.formAllTabEmployee.updateEmployeeMainInfoRequest =
                response.data.data

          })
          .catch((err) => {
            console.log(err);
          });
    },
    submitForm(fromData) {
      this.$refs[fromData].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.submitSettingCamera();
          this.pushDataToCheckBoxDepartment();
          this.selectionMultiProfessional();
          let iconArrayTeacher1 = this.teacherIconAppUpdate.teacherIconUpdate1;
          let iconArrayTeacher2 = this.teacherIconAppUpdate.teacherIconUpdate2;
          let iconArrayTeacher = iconArrayTeacher1.concat(iconArrayTeacher2);
          this.formAllTabEmployee.appIconTeacherRequestList = iconArrayTeacher;

          let iconArrayPlus1 = this.plusIconAppUpdate.plusIconUpdate1;
          let iconArrayPlus2 = this.plusIconAppUpdate.plusIconUpdate2;
          let iconArrayPlus = iconArrayPlus1.concat(iconArrayPlus2);
          this.formAllTabEmployee.appIconPlusRequestList = iconArrayPlus;

          let iconNotifyArrayPlus1 = this.notifyPlusIconAppUpdate.notifyPlusIconUpdate1;
          let iconNotifyArrayPlus2 = this.notifyPlusIconAppUpdate.notifyPlusIconUpdate2;
          let iconNotifyArrayPlus = iconNotifyArrayPlus1.concat(iconNotifyArrayPlus2);
          this.formAllTabEmployee.notifyAppIconPlusRequestList = iconNotifyArrayPlus;
          let formDatas = new FormData();

          formDatas.append(
              "idEmployee",
              this.formAllTabEmployee.updateEmployeeMainInfoRequest.id
          );
          formDatas.append(
              "fileName",
              this.formAllTabEmployee.updateEmployeeMainInfoRequest.avatar
          );
          if (this.fileAvatar != null && this.fileAvatar != "") {
            formDatas.append("multipartFile", this.fileAvatar);
            EmployeeDataService.uploadEditAvatar(formDatas)
                .then(() => {
                  this.updateEmployee(fromData);
                })
                .catch((err) => {
                  this.$message({
                    message: err.response.data.message,
                    type: "error",
                  });
                });
          } else {
            this.updateEmployee(fromData);
            // this.$parent.handleCurrentChangeAfter();
          }
        } else {
          console.log("error validate data!");
          return false;
        }
        this.showUpload = true;
      });
    },
    /**
     * cập nhật nhân viên
     */
    updateEmployee(fromData) {
      EmployeeDataService.update(this.idEmployeeEdit, this.formAllTabEmployee)
          .then((response) => {
            this.$message({
              message: response.data.message,
              type: "success",
            });
            this.closeDialogByButton(fromData);
          })
          .catch((err) => {
            this.$message({
              message: err.response.data.message,
              type: "error",
            });
          })
          .finally(() => {
            setTimeout(() => {
              this.loadingButton = false;
            }, 500);
          });
    },
    uploadEditAvatar(formData) {
      EmployeeDataService.uploadEditAvatar(formData)
          .then((res) => {
            console.log(res.data.data);
          })
          .catch((err) => {
            console.log(err);
          });
    },
    clickNameInitial(data) {
      if (data == "fullName") {
        this.showDetail = true;
        this.showButtonClick = false;
        this.showTitle();
      } else if (data == "noFullName") {
        this.showDetail = false;
        this.showButtonClick = true;
        this.showTitle();
      }
    },
    showTitle(){
      if (this.showDetail === false){
        return "Sửa nhân viên"
      }else return "Thông tin nhân viên"
    },
    getAllAccountType() {
      EmployeeDataService.getAccountType()
          .then((response) => {
            this.accountTypeList = response.data.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getTabDepartmentInEmployee() {
      EmployeeDataService.getTabDepartmentInEmployeeById(this.idEmployeeEdit)
          .then((response) => {
            this.formAllTabEmployee.tabDepartmentInEmployee = response.data.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getTabProfessionalInEmployee() {
      EmployeeDataService.getTabProfessionalInEmployeeById(this.idEmployeeEdit)
          .then((response) => {
            this.formAllTabEmployee.tabProfessionalInEmployee = response.data.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },

    tabTeacherIconMethod(id) {
      EmployeeDataService.findIconTeacherUpdate(id)
          .then((resp) => {
         this.teacherIconAppUpdate.teacherIconUpdate1 =
                resp.data.data.appIconTeacherResponseList1

            this.teacherIconAppUpdate.teacherIconUpdate2 =
                resp.data.data.appIconTeacherResponseList2;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    pushDataToCheckBoxDepartment() {
      let a = this.formAllTabEmployee.tabDepartmentInEmployee.filter(
          (item) => item.checkDepartment == true
      );
      this.formAllTabEmployee.tabDepartmentRequestList = a;
    },
    selectionMultiProfessional() {
      let a = this.formAllTabEmployee.tabProfessionalInEmployee.filter(
          (item) => item.checkIsClass == true
      );
      this.formAllTabEmployee.tabProfessionalRequestList = a;
    },
    toggleUpload(file, fileList) {
      this.fileList = fileList;
      this.showPicutreInitial = false;
      this.showUpload = !this.showUpload;
      this.fileAvatar = file.raw;
      this.formAllTabEmployee.updateEmployeeMainInfoRequest.avatar = this.fileAvatar.name;
    },
    handleRemove(file) {
      setTimeout(() => {
        this.showPicutreInitial = true;
        this.showUpload = !this.showUpload;
      }, 950);

      let index = this.fileList.indexOf(file);
      if (index >= 0) {
        this.fileList.splice(index, 1);
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible1 = true;
    },
    async getListCamera(id){
      await employeeDataService.getListCamera(id,"teacher").then((response) =>{
        this.employeeCamList = response.data
        this.idEmployee = id
      })
    },
    handleSettingCame(idCamera,nameCamera){
      this.showSettingCamera = true
      this.nameCamera = nameCamera
      this.idCamera = idCamera
    },
    handleCloseSettingCameraDialog(){
      this.showSettingCamera = false
    },
    async submitSettingCamera(){
      this.cameraByUserRequestList = []
      for (let i = 0; i < this.employeeCamList.length; i++) {
        let cameraSetting = {
          idUserCam: this.idEmployee,
          idCamera: 0,
          nameCam: "",
          typeUser: "teacher",
          active: false
        }
        if (this.employeeCamList[i].existClass === false){
          cameraSetting.idCamera = this.employeeCamList[i].idCamera
          cameraSetting.nameCam = this.employeeCamList[i].cameraName
          cameraSetting.active = this.employeeCamList[i].active
          this.cameraByUserRequestList.push(cameraSetting)
        }
      }
      let data = {
        cameraByUserRequestList: this.cameraByUserRequestList
      }
      await employeeDataService.saveSettingCameraPlus(data).then((res)=>{
        console.log(res.data)
      }).catch((error)=>{
        console.log(error)
      })
    },
  },
};
</script>
<style>
.hideUpload > div {
  display: none;
}
</style>
<style lang="scss" scoped>
// /deep/.el-input__inner,
// /deep/.el-textarea__inner {
//   border: none;
//   border-radius: 0;
//   resize: none;
//   padding-left: 0;
//   padding-right: 0;
//   border-bottom: 1px solid #d9d9d9;
// }
/deep/ .el-dialog__title {
  font-size: 25px;
  color: #606266;
}

/deep/ .el-form-item__label {
  font-family: Arial, Helvetica, sans-serif;
}

/deep/ .el-button--primary {
  width: 100%;
  background: #409eff;
}

/deep/ .upload-demo {
  text-align: center;

  .el-upload {
    width: 100%;
  }
}

/deep/ .el-avatar {
  display: inline;

  img {
    border-radius: 5px;
    width: 100%;
  }
}

/deep/ .el-input.is-disabled .el-input__inner {
  text-align: left;
  color: #d13b93;
  font-weight: bold;
}

.el-select {
  display: block;
}

.tab-infor-button {
  text-align: right;
  margin-top: 20px;
}

.bg-purple {
  background: #d3dce6;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.group-radio-status {
  margin: 0 50px;

  .kids-status {
    margin-bottom: 10px;
  }
}

.table-icon-1 {
  width: 30%;
  margin-left: 15%;
  display: inline-block;
}

.table-icon-2 {
  width: 30%;
  margin-right: 15%;
  float: right;
}

// /deep/.el-tabs__item {
//   color: white;
// }
// /deep/.el-tabs__item.is-active {
//   color: white;
//   background: #67c23a;
// }
// /deep/.el-tabs__nav {
//   background: #a0a19ce0;
// }
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
